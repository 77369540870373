import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '@core/models';
import { Observable } from 'rxjs';

@Injectable()
export class TeqSiteInterceptor implements HttpInterceptor {

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(this.config.apiUrl) || request.url.includes(this.config.butterApiUrl)) {
      request = request.clone({ setHeaders: { 'X-TEQ-SITE': this.config.teqSiteId } });
    }

    return next.handle(request);
  }

}

