import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { catchError } from 'rxjs';
import { Tenders } from '@app/tenders/models';
import { TendersService } from '@app/tenders/services';

export const tendersResolver: ResolveFn<Tenders[]> = () => {
  const tenderService = inject(TendersService);

  return tenderService.getTenders().pipe(catchError(() => []));
};
