/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPlatformBrowser } from '@angular/common';
import { AppConfig } from '@core/models';

declare var gtag: any;
declare var CookieFirst: any;

export function GoogleAnalyticsMethod(
  target: { platformId: any },
  propertyKey: string,
  descriptor: PropertyDescriptor
): void {
  const method = descriptor.value;

  descriptor.value = async function (...args: any[]): Promise<void> {
    const object = this as { platformId: any; config: AppConfig };

    if (!isPlatformBrowser(object.platformId) || !object.config.cookieFirstId) {
      return;
    }

    return await gaLoaded()
      .then((_: any) => {
        return method.apply(this, args);
      })
      .catch((_: any) => {
        return;
      });
  };
}

const gaLoaded = (): any => {
  return new Promise((resolve, reject) => {
    const timeStart = Date.now();
    const TIMEOUT = 30000;

    const _isLoaded = function (): void {
      if (Date.now() - timeStart > TIMEOUT) {
        reject();
        return;
      }
      if (
        typeof CookieFirst === 'object' &&
        typeof gtag === 'function' &&
        CookieFirst.hasConsented &&
        CookieFirst.consent?.advertising
      ) {
        resolve(true);
        return;
      } else {
        setTimeout(_isLoaded, 500);
      }
    };

    _isLoaded();
  });
};
