import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@core/services';
import * as Aos from 'aos';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService, MiscService } from '@shared/services';
import { tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DialogService } from '@app/dialog/services';
import { PromoteRussianVersionDialogComponent } from '@app/dialog/components';
import { APP_CONFIG, AppConfig, CookieStorageEntry } from '@core/models';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private miscService: MiscService,
    private dialogService: DialogService,
    private metaService: MetaService,
    private analyticsService: AnalyticsService,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  ngOnInit(): void {
    this.handleForeignIPs();
    this.handleRouteChanges();
    this.metaService.init();

    if (isPlatformBrowser(this.platformId)) {
      Aos.init({
        once: true,
        disable: 'mobile',
      });

      this.router.events.subscribe((event) => {
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      });

      ['scroll', 'resize', 'reset', 'load', 'rotate'].forEach((event) => {
        window.addEventListener(event, Aos.refresh);
      });
    }
  }

  private handleRouteChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((route) => {
          const navigationEnd = route as NavigationEnd;
          this.initTrackingServices(navigationEnd);
        })
      )
      .subscribe();
  }

  private initTrackingServices(route: NavigationEnd): void {
    if (isPlatformBrowser(this.platformId)) {
      this.analyticsService.sendPageView(route.url);
    }
  }

  private handleForeignIPs(): void {
    if (this.cookieService.check(CookieStorageEntry.foreignIpHandled)) {
      return;
    }

    const browserLanguage = navigator.language?.toUpperCase();

    this.miscService.resolveIp().subscribe(({ code }) => {
      switch (true) {
        case (browserLanguage === 'RU' || code === 'RU') && this.document.location.origin !== this.config.russianDomain:
          this.handleRussianIp();
          break;
        default:
          break;
      }
    });
  }

  private handleRussianIp(): void {
    this.dialogService
      .open(PromoteRussianVersionDialogComponent)
      .afterClosed()
      .subscribe((confirm) => {
        const nextMonth = new Date(new Date().setMonth(new Date().getMonth() + 1));
        this.cookieService.set(CookieStorageEntry.foreignIpHandled, '1', nextMonth);

        if (confirm && this.config.russianDomain) {
          window.location.href = this.config.russianDomain;
        }
      });
  }
}
