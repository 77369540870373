import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ButterTranslateLoader, LocalizationService } from '@core/localization';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { APP_CONFIG } from '@core/models';
import { Observable } from 'rxjs';
import { TeqSiteInterceptor } from '@core/interceptors';
import { FooterComponent, HeaderComponent } from '@app/layout/components';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    TransferHttpCacheModule,
    HeaderComponent,
    FooterComponent,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: ButterTranslateLoader,
        deps: [HttpClient, APP_CONFIG],
      },
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (service: LocalizationService) => (): Observable<any> => service.init(),
      multi: true,
      deps: [LocalizationService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: TeqSiteInterceptor, multi: true },
    CookieService,
  ],
})
export class AppModule {}
