import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageType } from '@app/content/models';
import { contentParamsResolver, contentSlugResolver } from '@app/content/resolvers';
import { tendersResolver } from '@app/tenders/resolvers';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('./home/components').then((m) => m.HomeComponent),
    resolve: {
      page: contentSlugResolver,
    },
    data: {
      slug: 'home',
      pageType: PageType.HOME,
    },
  },
  {
    path: 'career',
    loadComponent: () => import('./career/components').then((m) => m.CareerComponent),
    resolve: {
      page: contentSlugResolver,
    },
    data: {
      slug: 'career',
      pageType: PageType.CAREER,
    },
  },
  {
    path: 'contact-us',
    loadComponent: () => import('./contact/components').then((m) => m.ContactComponent),
    resolve: {
      page: contentSlugResolver,
    },
    data: {
      slug: 'contact',
      pageType: PageType.CONTACT,
    },
  },
  {
    path: 'reseller',
    loadComponent: () => import('./reseller/components').then((m) => m.ResellerComponent),
  },
  {
    path: 'tenders',
    loadComponent: () => import('./tenders/components').then((m) => m.TendersComponent),
    resolve: {
      tenders: tendersResolver,
    },
    data: {
      slug: 'teqball_tenders',
    },
  },
  {
    path: 'ownership-statement',
    loadComponent: () => import('./content/components').then((m) => m.DynamicDefaultPageComponent),
    resolve: {
      sitePage: contentSlugResolver,
    },
    data: { slug: 'teqball_ownership-statement' },
  },
  {
    path: 'tenders/:slug',
    loadComponent: () => import('./content/components').then((m) => m.DynamicDefaultPageComponent),
    resolve: {
      sitePage: contentParamsResolver,
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
