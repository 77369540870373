<div class="social-channels">
  <a
    class="social-channels__item icon teq-facebook"
    href="https://www.facebook.com/teqballofficial"
    target="_blank"
    rel="noopener"
    aria-label="Facebook"></a>
  <a
    class="social-channels__item icon teq-x"
    href="https://x.com/teqball/"
    target="_blank"
    rel="noopener"
    aria-label="X"></a>
  <a
    class="social-channels__item icon teq-instagram"
    href="https://www.instagram.com/teqball/"
    target="_blank"
    rel="noopener"
    aria-label="Instagram"></a>
  <a
    class="social-channels__item icon teq-tiktok"
    href="https://www.tiktok.com/@teqball?lang=en"
    target="_blank"
    rel="noopener"
    aria-label="TikTok"></a>
  <a
    class="social-channels__item icon teq-youtube"
    href="https://www.youtube.com/channel/UCfVGNkTL802SbmCAaXRwNaQ"
    target="_blank"
    rel="noopener"
    aria-label="YouTube"></a>
</div>
