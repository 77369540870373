<header class="header" *ngIf="menu$ | async as menu" [ngClass]="{ 'is-inverse': showNavigation() }">
  <div
    class="container split"
    [attr.data-aos]="(isMobile$ | async) === false ? 'fade-down' : null"
    [attr.data-aos-duration]="(isMobile$ | async) === false ? '1000' : null"
  >
    <div class="header__brand">
      <a class="logo__frame" [routerLink]="['/']">
        <img height="35" width="142" [src]="teqballLogo" [alt]="'LOGO_ALTERNATIVE_TEXT' | translate" />
      </a>
    </div>
    <nav class="header-navigation" [ngClass]="{ 'is-opened': showNavigation() }">
      <ul #navigation class="header-navigation__frame">
        <ng-container *ngFor="let fixedHeaderNavigationItem of fixedMenu">
          <li class="header-navigation__item">
            <a (click)="scrollNavigate(fixedHeaderNavigationItem.scrollTo)" class="text--bold">{{
              fixedHeaderNavigationItem.label | translate
            }}</a>
          </li>
        </ng-container>
        <ng-container *ngFor="let headerNavigationItem of menu">
          <li class="header-navigation__item">
            <a
              *ngIf="!(headerNavigationItem.url | isExtrenalLink); else navigationExternalLink"
              [routerLink]="[headerNavigationItem.url]"
              (click)="showNavigation.set(!showNavigation)"
              >{{ headerNavigationItem.label | translate }}</a
            >
            <ng-template #navigationExternalLink>
              <a
                [href]="[headerNavigationItem.url]"
                (click)="showNavigation.set(!showNavigation)"
                target="_blank"
                class="text--bold"
                >{{ headerNavigationItem.label | translate }}</a
              >
            </ng-template>
          </li>
        </ng-container>
      </ul>
    </nav>
    <button
      class="nav-icon nav-icon--squeeze"
      (click)="showNavigation.set(!showNavigation())"
      [ngClass]="{ 'is-opened': showNavigation() }"
    >
      <div class="nav-icon__lines"></div>
    </button>
  </div>
</header>
