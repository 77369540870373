import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CareerPage, ContactPage, HomePage, PageType } from '@app/content/models';
import { ContentService } from '@app/content/services/content.service';

export type Content = HomePage | CareerPage | ContactPage;

export const contentSlugResolver: ResolveFn<Content> = (route: ActivatedRouteSnapshot, _: RouterStateSnapshot) => {
  const contentService = inject(ContentService);
  const router = inject(Router);

  const pageType: PageType = route.data?.['pageType'] || PageType.ALL;

  return contentService.fetchContent<Content>(pageType, route.data?.['slug'] || route.url).pipe(
    catchError((_) => {
      router.navigate(['/not-found']);
      return EMPTY;
    })
  );
};
