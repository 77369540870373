/* eslint-disable @typescript-eslint/no-explicit-any */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AppConfig, APP_CONFIG } from '@core/models';
import { GoogleAnalyticsMethod } from '@shared/decorators/google-analytics-method.decorator';

// eslint-disable-next-line no-var
declare var gtag: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    @Inject(PLATFORM_ID) public platformId: any,
    @Inject(DOCUMENT) public document: any,
    @Inject(APP_CONFIG) public config: AppConfig
  ) {}

  @GoogleAnalyticsMethod
  sendPageView(path: string): void {
    gtag('event', 'page_view', {
      /* eslint-disable camelcase */
      page_title: document.title,
      page_location: path,
      page_path: path,
    });
  }
}
