import { Injectable } from '@angular/core';
import { ButterService } from '@core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tenders } from '@app/tenders/models';

@Injectable({
  providedIn: 'root',
})
export class TendersService {
  constructor(private butter: ButterService) {}

  public getTenders(): Observable<Tenders[]> {
    return this.butter.collection('teqball_tenders').pipe(map((result) => result.data.teqball_tenders));
  }
}
