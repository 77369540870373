import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TEQBALL_BRAND_IMAGE } from '@shared/constants';
import { MenuService } from '@app/layout/services';
import { Breakpoints, LegalMenuItem, MenuItem } from '@app/layout/models';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ExternalLinkPipe } from '@shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { SocialChannelsComponent } from '@app/layout/components';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, NgFor, NgIf, SocialChannelsComponent, AsyncPipe, TranslateModule, ExternalLinkPipe],
})
export class FooterComponent {
  public isMobile$: Observable<boolean>;
  public now: number = new Date().getFullYear();

  public teqballLogo = TEQBALL_BRAND_IMAGE;

  public footerMenu$: Observable<MenuItem[]>;
  public legalMenu$: Observable<LegalMenuItem[]>;

  constructor(public menuService: MenuService, public breakpointObserver: BreakpointObserver) {
    this.isMobile$ = this.breakpointObserver
      .observe(`(max-width: ${Breakpoints.DESK}px)`)
      .pipe(map((breakpoints) => breakpoints.matches));

    this.legalMenu$ = menuService
      .getAdditionalMenuItems()
      .pipe(map((legalMenuItems) => legalMenuItems.map((legalMenuItem) => new LegalMenuItem(legalMenuItem))));
    this.footerMenu$ = menuService.getFooterItems().pipe(map((menuItems) => menuItems.map((menuItem) => menuItem)));
  }

  public onCollapseTitleClick(event: MouseEvent): void {
    (event.target as Element).closest('.footer-collapse')?.classList.toggle('is-open');
  }
}
