import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { APP_CONFIG, AppConfig } from '@core/models';

fetch(`./assets/config/default.config.json?date=${new Date().valueOf()}`)
  .then((response) => response.json())
  .then((config: AppConfig) => {
    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
