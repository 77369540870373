<footer class="footer"
        [attr.data-aos]="(isMobile$ | async) === false ? 'fade-up' : null"
        [attr.data-aos-duration]="(isMobile$ | async) === false ? '1000' : null"
        [attr.data-aos-delay]="(isMobile$ | async) === false ? '250' : null">
  <div class="container">
    <div class="footer__section footer__section--top">
      <div class="footer__brand">
        <a class="logo__frame" [routerLink]="['/']">
          <img height="35" width="142" [src]="teqballLogo" [alt]="'LOGO_ALTERNATIVE_TEXT' | translate"  loading="lazy"/>
        </a>
        <p>{{ 'FOOTER_TRADEMARK_TEXT' | translate }}</p>
        <div class="wrapper--social">
          <app-social-channels></app-social-channels>
        </div>
      </div>
      <nav class="footer__menu">
        <div class="footer__column footer-collapse" *ngFor="let category of footerMenu$ | async">
          <p class="footer__heading footer__heading--collapsable" (click)="onCollapseTitleClick($event)">
            <span>{{ category.label }}</span>
            <i class="job-offer__arrow teq-arrow-right2"></i>
          </p>
          <ul class="footer-collapse__frame">
            <li class="footer-collapse__item" *ngFor="let categoryItem of category.children">
              <a *ngIf="!(categoryItem.url | isExtrenalLink) else categoryExternalLink" [routerLink]="categoryItem.url">{{
                categoryItem.label | translate
              }}</a>
              <ng-template #categoryExternalLink>
                <a [href]="categoryItem.url" target="_blank" rel="noopener">{{
                  categoryItem.label
                }}</a>
              </ng-template>
            </li>
          </ul>
        </div>
      </nav>
      <div class="footer__table-finder">
        <p>{{ 'FOOTER_TABLE_FINDER_DESCRIPTION' | translate }}</p>
        <a class="button button--primary split" href="https://eu.teqers.com/table-finder" target="_blank"
          >{{ 'FOOTER_TABLE_FINDER_BUTTON' | translate }}
          <i class="teq-arrow-right2"></i>
        </a>
      </div>
    </div>
    <div class="footer__section footer__section--bottom">
      <div class="wrapper--legal">
        <p>{{ 'TEQBALL_ALL_RIGHTS_RESERVED' | translate: { now: this.now } }}</p>
        <ng-container *ngFor="let legalMenuItem of legalMenu$ | async">
          <a *ngIf="!(legalMenuItem.url | isExtrenalLink) else legalMenuExternalLink" [routerLink]="legalMenuItem.url">{{
            legalMenuItem.label | translate
            }}</a>
          <ng-template #legalMenuExternalLink>
            <a [href]="legalMenuItem.url" target="_blank" rel="noopener">{{
              legalMenuItem.label
              }}</a>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</footer>
