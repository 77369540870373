import { Injectable } from '@angular/core';
import { HttpService } from '@core/services';
import { Country } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MiscService {
  constructor(private http: HttpService) {}

  getCountries(): Observable<Country[]> {
    return this.http.get('/v1/misc/countries');
  }

  resolveIp(): Observable<Country> {
    return this.http.get('/v1/misc/resolve');
  }
}
