import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ButterService } from '@core/services';
import { PageType } from '@app/content/models';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(private butter: ButterService) {}

  public fetchContent<T>(pageType: PageType, slug: string): Observable<T> {
    return this.butter.page(pageType, slug).pipe(map((result) => result.data.fields));
  }
}
